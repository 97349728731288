const Config = {
  hostConfigurationsUrl: "/huddle_host_configurations.json",
  newHostConfigurationUrl: "/huddle_host_configurations/new",
  hostSettingName: "oss-outlook-room-finder-host",
} as const;

const getBackUrl = (): string => {
  const meta = document.querySelector("meta[name=back-url]") as HTMLMetaElement;

  return meta.content;
};

const postHostConfiguration = async (payload: Record<string, string>) => {
  const response = await fetch(Config.hostConfigurationsUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  return await response.json();
};

const redirectToWelcomeScreen = () => {
  document.location.replace(
    `${Config.newHostConfigurationUrl}?back_url=${getBackUrl()}`
  );
};

const postHostAndRedirectBackWithHostParam = async (host: string) => {
  const json = await postHostConfiguration({ host, back_url: getBackUrl() });
  const url = json["back_url_with_host_param"];

  if (url) {
    document.location.replace(url as string);
  }
};

export const redirectToStoredHostOrWelcomeScreen = () =>
  Office.onReady(async () => {
    const host: string | void = Office.context.roamingSettings?.get(
      Config.hostSettingName
    );

    if (host) {
      await postHostAndRedirectBackWithHostParam(host);
    } else {
      redirectToWelcomeScreen();
    }
  });

export const storePertinentHostAndRedirectBackWithHostParam = (
  host: string
) => {
  localStorage.setItem(`pertinent/${Config.hostSettingName}`, host);
  postHostAndRedirectBackWithHostParam(host);
};
