import React, { useEffect } from "react";

import { Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";

import { redirectToStoredHostOrWelcomeScreen } from "./office";

const styles = {
  root: {
    backgroundColor: NeutralColors.gray20,
    padding: "24px 36px",
    height: "100%",
  },
};

const LoadingScreen = () => {
  useEffect(() => {
    redirectToStoredHostOrWelcomeScreen();
  }, []);

  return (
    <Stack style={styles.root} verticalAlign="center" horizontalAlign="center">
      <Spinner size={SpinnerSize.large} />
    </Stack>
  );
};

export default LoadingScreen;
